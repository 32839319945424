import { i18n } from '@/i18n/setup'

export default {
  /**
   * Получение корневых родительских групп без потомков
   *
   * @param {Object} state
   * @returns {Array}
   */
  getWikiWithoutChildren(state) {
    return state.wiki.filter((item) => !item.children?.length)
  },

  /**
   * Получение корневых родительских групп с потомками
   *
   * @param {Object} state
   * @returns {Array}
   */
  getWikiWithChildren(state) {
    return state.wiki.filter((item) => item.children?.length)
  },

  /**
   * Получение вики по id
   *
   * @param {Object} state
   * @returns {function(id):Object|null}
   */
  getWikiById(state) {
    return (id) => {
      let result = null

      const findWIkiById = (arr) => {
        arr.forEach((item) => {
          if (item.id === Number(id)) {
            result = item
          }

          if (item.children.length) {
            findWIkiById(item.children)
          }
        })
      }

      findWIkiById(state.wiki)

      return result
    }
  },

  /**
   * Получение корневой родительской группы вики по id потомка
   *
   * @param {Object} state
   * @param {Object} getters
   * @returns {function(id):Object}
   */
  getRootParentWiki(state, getters) {
    return (id) => {
      let result = {}
      let parent = {}

      const findParentById = (arr) => {
        arr.forEach((item) => {
          const currentParent = getters.getWikiWithUngroupedItems.find(
            (value) => value.id === item.id,
          )

          if (currentParent) {
            parent = currentParent
          }

          if (item.id === id) {
            result = parent
          } else if (item?.children?.length) {
            findParentById(item.children)
          }
        })
      }

      findParentById(getters.getWikiWithUngroupedItems)

      return result
    }
  },

  /**
   * Получение списка имеющихся групп и новой группы,
   * в которой объединены группы без родителя
   *
   * @param {Object} state
   * @param {Object} getters
   * @returns {Array}
   */
  getWikiWithUngroupedItems(state, getters) {
    if (getters.getWikiWithoutChildren.length) {
      const restWikiGroup = {
        id: getters.getWikiWithoutChildren[0].id,
        name: i18n.t('pages.settings.the_wiki.other'),
        children: getters.getWikiWithoutChildren,
        wikiContent: [],
      }

      return [...getters.getWikiWithChildren, restWikiGroup]
    }

    return getters.getWikiWithChildren
  },
}
