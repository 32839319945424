import { EducontLogRowsPerPage } from '@admin/const/EducontLogRowsPerPage'
import { Pagination } from '@admin/helpers/admin/CompositePagination'
import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {{
   *   id: number,
   *   transactionId: string,
   *   status: string,
   *   error: string[],
   *   createdAt: string
   * }[]} data
   */
  setLog(state, data) {
    state.log = data.map(({ id, transactionId, status, error, createdAt }) => ({
      id,
      transactionId,
      status,
      error,
      createdAt,
    }))
  },

  /**
   * @param {Object} state
   * @param {Object} headers
   */
  setLogPagination(state, headers) {
    const compositePagination = new Pagination({
      rowsPerPage: EducontLogRowsPerPage,
    })

    compositePagination.updateFromHeaders(headers)

    const paginationState = state.logPagination

    if (paginationState !== null) {
      compositePagination.setRowsPerPage(paginationState.rowsPerPage)
    }

    state.logPagination = compositePagination
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {number} args.value
   */
  setLogPaginationRowsPerPage(state, { value }) {
    state.logPagination.setRowsPerPage(value)
  },
}
