export default {
  /**
   * Одноуровневый список всех групп вики.
   *
   * @param {Object} state
   * @returns {function(id):Array}
   * @description id - элемент с указанным id
   * исключается из результирующего массива
   */
  getWikiFlattened(state) {
    return (id) => {
      const result = []

      const flattenItems = (arr) =>
        arr.forEach((item) => {
          if (item.id !== Number(id)) {
            result.push({
              id: item.id,
              name: item.name,
            })
          }

          if (item?.children?.length) {
            flattenItems(item.children)
          }
        })

      flattenItems(state.wiki)

      return result
    }
  },

  /**
   * Список корневых родительских групп без потомков
   *
   * @param {Object} state
   * @returns {Array}
   */
  getWikiWithoutChildren(state) {
    if (state.wikiWithoutChildren.length) {
      return state.wikiWithoutChildren
    }

    return state.wiki.filter((item) => !item.children?.length)
  },

  /**
   * Список корневых родительских групп с потомками
   *
   * @param {Object} state
   * @returns {Array}
   */
  getWikiWithChildren(state) {
    if (state.wikiWithChildren.length) {
      return state.wikiWithChildren
    }

    return state.wiki.filter((item) => item.children?.length)
  },

  /**
   * Список контента
   *
   * @param {Object} state
   * @returns {Array}
   */
  getWikiContent(state) {
    let result = []
    const newContent = state.option.materialTypes
    const existingContent = state.option.content.map((item) => ({
      id: Number(item.id),
      typeId: Number(item.type.id),
      name: item.type.name,
      comment: item.comment,
    }))

    if (existingContent.length) {
      result = [
        {
          name: 'Новые элементы',
          isNew: true,
          options: [...newContent],
        },
        {
          name: 'Существующие элементы',
          isNew: false,
          options: [...existingContent],
        },
      ]
    } else {
      result = [
        {
          name: 'Новые элементы',
          options: [...newContent],
        },
      ]
    }

    return result
  },
}
