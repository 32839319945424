<template>
  <div class="uiKit-navigation-group">
    <UiKitNavigationGroupHeader
      :visible="visible"
      :item="clonedItem"
      @toggle="processGroupToggle"
    />

    <UiKitNavigationGroupBody
      v-if="hasItems"
      :item="clonedItem"
      @toggle="processSubGroupsToggle"
    />
  </div>
</template>

<script>
import UiKitNavigationGroupBody from '@admin/ui/admin/UiKitNavigationGroupBody.vue'
import UiKitNavigationGroupHeader from '@admin/ui/admin/UiKitNavigationGroupHeader.vue'
import { cloneDeep } from 'lodash'

export default {
  name: 'UiKitNavigationGroup',

  components: {
    UiKitNavigationGroupHeader,
    UiKitNavigationGroupBody,
  },

  props: {
    /**
     * Current item and its children
     */
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      clonedItem: cloneDeep(this.$props.item),
    }
  },

  computed: {
    visible() {
      return this.clonedItem.visible ? this.clonedItem.visible : false
    },

    hasItems() {
      return !!this.clonedItem.items?.length
    },
  },

  watch: {
    item: {
      handler() {
        // Sync local data with a prop
        this.clonedItem = cloneDeep(this.$props.item)
      },

      deep: true,
    },
  },

  methods: {
    /**
     * Update current group visibility
     *
     * @param {Event} e
     */
    processGroupToggle(e) {
      this.clonedItem.visible = e
      this.$emit('toggle', cloneDeep(this.clonedItem))
    },

    /**
     * Update sub groups visibility
     *
     * @param {Event} e
     */
    processSubGroupsToggle(e) {
      this.$set(this.$data, 'clonedItem', e)
      this.$emit('toggle', cloneDeep(this.clonedItem))
    },
  },
}
</script>

<style lang="scss">
@use "sass:math";

@import '~@admin/scss/functions';
@import '~@admin/scss/bootstrap/variables';

.uiKit-navigation-group {
  // Spacing between groups
  padding: 0 0 20px;

  // Sub groups
  & & {
    padding: 0 0 0 (20px + 15px) + math.div(20px, 1.5);
  }
}
</style>
