import ApiBase from 'ApiRest/ApiBase'

export const ApiBanner = new (class extends ApiBase {
  /**
   * Получение данных об определённом баннере
   *
   * @param {number} bannerId
   * @returns {Promise}
   */
  get(bannerId) {
    return this._GET(`/banner/${bannerId}`)
  }

  /**
   * Создание баннера
   *
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/banner`, data)
  }

  /**
   * Изменение баннера
   *
   * @param {number} bannerId
   * @param {Object} data
   * @returns {Promise}
   */
  patch(bannerId, data) {
    return this._PATCH(`/banner/${bannerId}`, data)
  }

  /**
   * Удаление баннера
   *
   * @param {number} bannerId
   * @returns {Promise}
   */
  delete(bannerId) {
    return this._DELETE(`/banner/${bannerId}`)
  }
})()
