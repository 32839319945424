import { userActions } from '@admin/accessControl/userActions'
import { getActiveModule } from 'Helpers/accessControl/module'

export const setPermissionByModuleChat = ({ activeModules, helpers }) => {
  const module = getActiveModule(activeModules, 'chat')

  if (module !== undefined) {
    helpers.allowReadMenuItemForRoutes(['admin-messages'])

    helpers.allowCrudRoutes(['admin-messages'])

    helpers.allowActionOnRoutes(userActions.ENABLE_CHAT, [
      'admin-main',
      'admin-messages',
    ])

    return true
  }

  return false
}
