import ApiPermissions from 'ApiRest/Api/User/Permissions'
import { getCookie } from 'Helpers/cookie'
import { LocaleControllerForSchoolManager } from 'Helpers/models/LocaleControllerForSchoolManager'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  // TODO: перенести это из модуля навигации
  async fetchProfile({ commit }) {
    const { data } = await ApiPermissions.get()

    commit('setProfile', data)

    const localeController = new LocaleControllerForSchoolManager({
      lang: getCookie('lang') || 'ru',
    })

    await localeController.changeLocale()

    commit('setTextsLoaded', true)
  },
}
