const routes = [
  {
    path: '/library/subject/create',
    name: 'admin-the-library-subject-create',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-subject" */ '@admin/pages/admin/library/TheLibrarySubject.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/library/subject/:subjectId(\\d+)',
    name: 'admin-the-library-subject',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-subject" */ '@admin/pages/admin/library/TheLibrarySubject.vue'
      ),
  },
  {
    path: '/library/subjects',
    name: 'admin-library-subjects',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-library-subjects" */ '@admin/pages/admin/library/LibrarySubjects.vue'
      ),
  },
  {
    path: '/library/sections',
    name: 'admin-library-sections',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-library-sections" */ '@admin/pages/admin/library/LibrarySections.vue'
      ),
  },
  {
    path: '/library/section/create',
    name: 'admin-the-library-section-create',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-section-create" */ '@admin/pages/admin/library/TheLibrarySection.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/library/section/:sectionId(\\d+)',
    name: 'admin-the-library-section',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-section" */ '@admin/pages/admin/library/TheLibrarySection.vue'
      ),
  },
  {
    path: '/library/section/:sectionId(\\d+)/topic/create',
    name: 'admin-the-library-topic-create',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-topic" */ '@admin/pages/admin/library/TheLibraryTopic.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/library/section/:sectionId(\\d+)/topic/:topicId(\\d+)',
    name: 'admin-the-library-topic',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-topic" */ '@admin/pages/admin/library/TheLibraryTopic.vue'
      ),
  },
  {
    path:
      '/library/section/:sectionId(\\d+)/topic/:topicId(\\d+)/question/create',
    name: 'admin-the-library-question-create',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-question" */ '@admin/pages/admin/library/TheLibraryQuestion.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path:
      '/library/section/:sectionId(\\d+)/topic/:topicId(\\d+)/question/:questionId(\\d+)',
    name: 'admin-the-library-question',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-question" */ '@admin/pages/admin/library/TheLibraryQuestion.vue'
      ),
  },
  {
    path: '/library/training-programs',
    name: 'admin-library-training-programs',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-library-training-programs" */ '@admin/pages/admin/library/LibraryTrainingPrograms.vue'
      ),
  },
  {
    path: '/library/training-program/create',
    name: 'admin-the-library-training-program-create',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-training-program" */ '@admin/pages/admin/library/TheLibraryTrainingProgram.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/library/training-program/:trainingProgramId(\\d+)',
    name: 'admin-the-library-training-program',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-training-program" */ '@admin/pages/admin/library/TheLibraryTrainingProgram.vue'
      ),
  },
  {
    path: '/library/moderation/contents',
    name: 'admin-library-moderation-contents',
    meta: {
      accessControlGroup: 'library-moderation',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-library-contents" */ '@admin/pages/admin/library/LibraryContents.vue'
      ),
  },
  {
    path: '/library/moderation/content/detail/:contentId(\\d+)',
    name: 'admin-the-library-moderation-content',
    meta: {
      accessControlGroup: 'library-moderation',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-content" */ '@admin/pages/admin/library/TheLibraryContent.vue'
      ),
    props: {
      isModeration: true,
    },
  },
  {
    path:
      '/library/section/:sectionId(\\d+)/topic/:topicId(\\d+)/content/create',
    name: 'admin-the-library-content-create',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-content" */ '@admin/pages/admin/library/TheLibraryContent.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path:
      '/library/section/:sectionId(\\d+)/topic/:topicId/content/:contentId(\\d+)',
    name: 'admin-the-library-content',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-content" */ '@admin/pages/admin/library/TheLibraryContent.vue'
      ),
  },
  {
    path: '/library/etags',
    name: 'admin-library-etags',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-library-etags" */ '@admin/pages/admin/library/LibraryEtags.vue'
      ),
  },
  {
    path: '/library/moderation/questions',
    name: 'admin-library-moderation-questions',
    meta: {
      accessControlGroup: 'library-moderation',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-library-questions" */ '@admin/pages/admin/library/LibraryQuestions.vue'
      ),
  },
  {
    path: '/library/moderation/question/detail/:questionId(\\d+)',
    name: 'admin-the-library-moderation-question',
    meta: {
      accessControlGroup: 'library-moderation',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-question" */ '@admin/pages/admin/library/TheLibraryQuestion.vue'
      ),
    props: {
      isModeration: true,
    },
  },
  {
    path: '/library/trash/topics',
    name: 'admin-library-trash-topics',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-library-trash-topics" */ '@admin/pages/admin/library/LibraryTrashTopics.vue'
      ),
  },
  {
    path: '/library/trash/topic/detail/:topicId(\\d+)',
    name: 'admin-the-library-trash-topic',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-topic" */ '@admin/pages/admin/library/TheLibraryTopic.vue'
      ),
  },
  {
    path: '/library/trash/contents',
    name: 'admin-library-trash-contents',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-library-contents" */ '@admin/pages/admin/library/LibraryContents.vue'
      ),
    props: {
      isDeleted: true,
    },
  },
  {
    path: '/library/trash/content/detail/:contentId(\\d+)',
    name: 'admin-the-library-trash-content',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-content" */ '@admin/pages/admin/library/TheLibraryContent.vue'
      ),
  },
  {
    path: '/library/trash/questions',
    name: 'admin-library-trash-questions',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-library-questions" */ '@admin/pages/admin/library/LibraryQuestions.vue'
      ),
    props: {
      isDeleted: true,
    },
  },
  {
    path: '/library/trash/question/detail/:questionId(\\d+)',
    name: 'admin-the-library-trash-question',
    meta: {
      accessControlGroup: 'library',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-library-question" */ '@admin/pages/admin/library/TheLibraryQuestion.vue'
      ),
  },
]

export default routes
