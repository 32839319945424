import AccessControlRules from '@admin/accessControl/rules'
import { userActions } from '@admin/accessControl/userActions'
import admin from '@admin/routes/admin'
import NotFound from '@frontend/pages/NotFound.vue'
import { ApiModulatorActiveModules } from 'ApiRest/Api/Modulator/ActiveModules/List'
import ApiPermissions from 'ApiRest/Api/User/Permissions'
import { authUrl } from 'Helpers/const/authUrl'
import { redirectToAuth, redirectToUserArea } from 'Helpers/redirectHelper'
import Router from 'vue-router'

export default new (class AdminRouter {
  constructor() {
    this.router = null
    this.ability = AccessControlRules.ability
    this.rules = null
  }

  /**
   * @param {Object} args
   * @param {Object[]} args.modules
   * @param {string[]} args.permissions
   * @private
   */
  async setAbilityFor({ modules, permissions }) {
    if (this.rules === null) {
      this.rules = AccessControlRules.defineFor({
        permissions,
        modules,
      })
    }
  }

  /**
   * @private
   * @returns {Promise<boolean>}
   */
  async getPermissionsAndCheckRole() {
    try {
      const {
        data: { baseRole, permissions },
      } = await ApiPermissions.get()

      if (baseRole === 'manager') {
        if (this.rules === null) {
          const { data } = await ApiModulatorActiveModules.get()

          await this.setAbilityFor({
            modules: data,
            permissions,
          })
        }

        return true
      }

      return redirectToUserArea()
    } catch (e) {
      // Бросить исключение дальше если это не HTTP-ошибка
      if (e.response === undefined) {
        throw e
      }
    }

    return redirectToAuth(authUrl.login, window.location.href)
  }

  /**
   * @param {Object} route
   * @param {string} route.name
   * @returns {boolean}
   * @private
   */
  canAccessRoute(route) {
    if (this.rules === null) {
      return false
    }

    // Для страниц создания сущностей проверять наличие CREATE способности
    if (route.name.substring(route.name.length - 7) === '-create') {
      return this.ability.can(userActions.CREATE, route)
    }

    return this.ability.can(userActions.READ, route)
  }

  init() {
    this.router = new Router({
      mode: 'history',

      routes: [
        admin,

        {
          path: '/go-away',
          name: 'forbidden',
          component: NotFound,
          props: {
            errorText: 'Доступ запрещен',
          },
        },

        {
          path: '*',
          name: 'not-found',
          component: NotFound,
        },
      ],

      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        }

        return {
          x: 0,
          y: 0,
        }
      },
    })

    const processBeforeEach = async (to, from, next) => {
      await this.getPermissionsAndCheckRole()

      if (
        !['not-found', 'forbidden'].includes(to.name) &&
        !this.canAccessRoute(to)
      ) {
        return next({
          replace: true,
          name: 'forbidden',
        })
      }

      return next()
    }

    this.router.beforeEach(processBeforeEach)

    return this.router
  }
})()
