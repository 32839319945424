import { RESET_STATE } from 'Helpers/ResetState'
import { cloneDeep, isUndefined, omit } from 'lodash'
import Vue from 'vue'

import createState from './state'

const getTheGradeSubjectSettingsTemplate = (gradeId) => ({
  grade: {
    id: gradeId,
    name: gradeId,
  },
  // TODO: mvp hardcoded
  subjectSettings: [
    {
      subject: {
        id: 1,
        name: 'Русский язык',
      },
      checker: null,
    },
    {
      subject: {
        id: 20,
        name: 'Алгебра',
      },
      checker: null,
    },
    {
      subject: {
        id: 7,
        name: 'Геометрия',
      },
      checker: null,
    },
  ],
})

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSchools(state, payload) {
    state.schools = payload
  },

  setApplications(state, payload) {
    state.applications = payload
  },

  setApplication(state, payload) {
    state.application = payload
  },

  setServices(state, payload) {
    state.services = payload.map((service) => omit(service, ['price']))
  },

  setApplicationStatuses(state, payload) {
    state.applicationStatuses = payload
  },

  setConfiguration(state, payload) {
    state.configuration = payload
  },

  setOptions(state, { key, data }) {
    if (isUndefined(state.options[key])) {
      Vue.set(state, 'options', key)
    }

    state.options[key] = data
  },

  setSettingsSubjects(state, payload = []) {
    state.gradeSubjectSettings = payload
  },

  changeGradeSubjectSettings(state, grades) {
    const gradesIds = grades.map((i) => i.id)
    let settings = cloneDeep(state.gradeSubjectSettings)

    settings = settings.filter((setting) =>
      gradesIds.includes(setting.grade.id),
    )
    const settingsGradesIds = settings.map((setting) => setting.grade.id)

    gradesIds.forEach((gradeId) => {
      if (!settingsGradesIds.includes(gradeId)) {
        settings.push(getTheGradeSubjectSettingsTemplate(gradeId))
      }
    })

    state.gradeSubjectSettings = settings
  },

  changeChecker(state, { checker, gradeId, subjectId }) {
    const gradeSetting = state.gradeSubjectSettings.find(
      (setting) => setting.grade.id === gradeId,
    )
    const subjectSetting = gradeSetting.subjectSettings.find(
      (setting) => setting.subject.id === subjectId,
    )

    subjectSetting.checker = checker
  },

  changeApplicationStatus(state, payload) {
    state.application.status = payload
  },

  addRetake(state, payload) {
    state.application.retakes.push(payload)
  },

  setManagers(state, managers) {
    state.managers = managers
  },
}
