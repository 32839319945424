import Constants from 'Constants'

const GRADE_FOR_CERTIFICATE_SCAN = 10

export default {
  getCertificationTests(state) {
    const { tests } = state.theRequest.certification

    return tests.map((test) => ({
      id: test.id,
      name: test.test.name,
      group: test.group,
      subject: test.test.subject,
    }))
  },

  getMainRequiredTests(state, getters) {
    return getters.getCertificationTests.filter(
      (test) =>
        test.group.id ===
        Constants.familyEducationCertificationTestGroup.MAIN_REQUIRED,
    )
  },

  getRequiredTests(state, getters) {
    return getters.getCertificationTests
      .filter(
        (test) =>
          test.group.id ===
          Constants.familyEducationCertificationTestGroup.REQUIRED,
      )
      .sort((a, b) => a.subject.id - b.subject.id)
  },

  getOptionalTests(state, getters) {
    return getters.getCertificationTests
      .filter(
        (test) =>
          test.group.id ===
          Constants.familyEducationCertificationTestGroup.OPTIONAL,
      )
      .sort((a, b) => a.subject.id - b.subject.id)
  },

  isCertificateScanFileAvailable(state) {
    return (
      state.theRequest.certification.grade?.id === GRADE_FOR_CERTIFICATE_SCAN
    )
  },
}
