import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiSupportQuestionNewCount = new (class extends ApiBase {
  /**
   * Вопросы со статусом "Новый" в отделах сотрудника.
   *
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters) {
    const url = `/support/question/new-count?${UrlHelper.param(parameters)}`

    return this._GET(url)
  }
})()
