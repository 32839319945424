const routes = [
  {
    path: '/income-diagnostics/applications',
    name: 'admin-income-diagnostics-applications',
    meta: {
      accessControlGroup: 'income-diagnostics',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-income-diagnostics" */ '@admin/pages/admin/incomeDiagnostics/IncomeDiagnosticsApplications.vue'
      ),
  },
  {
    path: '/income-diagnostics/application/:applicationId(\\d+)',
    name: 'admin-the-income-diagnostics-application',
    meta: {
      accessControlGroup: 'income-diagnostics',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-income-diagnostics" */ '@admin/pages/admin/incomeDiagnostics/TheIncomeDiagnosticsApplication.vue'
      ),
  },
]

export default routes
