var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uiKit-navigation-group" },
    [
      _c("UiKitNavigationGroupHeader", {
        attrs: { visible: _vm.visible, item: _vm.clonedItem },
        on: { toggle: _vm.processGroupToggle },
      }),
      _vm._v(" "),
      _vm.hasItems
        ? _c("UiKitNavigationGroupBody", {
            attrs: { item: _vm.clonedItem },
            on: { toggle: _vm.processSubGroupsToggle },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }