import { ApiOrderList } from 'ApiRest/Api/Products/Orders/OrderList'

export default {
  async fetchOrders({ commit }, filters = {}) {
    const { data, headers } = await ApiOrderList.get(filters)

    commit('setFilters', filters)
    commit('setOrders', data)
    commit('setPagination', headers)
  },
}
