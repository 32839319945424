<template>
  <button
    v-if="loadOnClick"
    class="btn"
    :class="cssClass"
    type="button"
    :disabled="isDisabled"
    @click="onClick"
  >
    <span
      v-if="isLoading"
      class="spinner-border spinner-border-sm"
      :class="$style.spinner"
      role="status"
      aria-hidden="true"
    ></span>

    <span :class="$style.label">{{ label }}</span>

    <slot></slot>
  </button>

  <button
    v-else
    class="btn"
    :class="cssClass"
    type="button"
    :disabled="isDisabled"
    v-on="$listeners"
  >
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm"
      :class="$style.spinner"
      role="status"
      aria-hidden="true"
    ></span>

    <span :class="$style.label">{{ label }}</span>

    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'UiKitButton',

  props: {
    label: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    loadOnClick: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    isDisabled() {
      return this.disabled || this.loading || this.isLoading
    },

    cssClass() {
      return [
        this.$style.button,
        {
          [this.$style.loading]: this.loading,
        },
      ]
    },
  },

  methods: {
    onClick() {
      if (this.isLoading) {
        return false
      }

      this.isLoading = true

      this.$emit('click', () => {
        this.isLoading = false
      })

      return true
    },
  },
}
</script>

<style module lang="scss">
.button {
  position: relative;
}

.loading {
  .label {
    opacity: 0;
  }
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
</style>
