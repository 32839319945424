import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiOnlineLessonCountByRepeatEndDate = new (class extends ApiBase {
  /**
   * Получение инфо по количеству уроков
   *
   * @param {Object} params
   * @param {number} params.onlineLessonId
   * @param {string|null} params.newEndDate
   * @param {number} params.repeatTypeId
   * @returns {Promise<{data: {count: number}}>}
   */
  get({ onlineLessonId, newEndDate = null, repeatTypeId }) {
    const parameters = {
      newEndDate,
      repeatTypeId,
    }

    return this._GET(
      `/online-lessons/count/${onlineLessonId}?${UrlHelper.param(parameters)}`,
    )
  }
})()
