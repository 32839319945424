// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingElement__spinner-border-size-small__DmDw {\n  width: 1rem !important;\n  height: 1rem !important;\n}\n.LoadingElement__spinner-border-size-middle__bsMP {\n  width: 2rem !important;\n  height: 2rem !important;\n}\n.LoadingElement__spinner-border-size-large__Rsmm {\n  width: 3rem !important;\n  height: 3rem !important;\n}", "",{"version":3,"sources":["webpack://./webpack/@admin/ui/admin/LoadingElement.vue"],"names":[],"mappings":"AAmEE;EACE,sBAAA;EACA,uBAAA;AAlEJ;AAqEE;EACE,sBAAA;EACA,uBAAA;AAnEJ;AAsEE;EACE,sBAAA;EACA,uBAAA;AApEJ","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.spinner-border-size {\n  &-small {\n    width: 1rem !important;\n    height: 1rem !important;\n  }\n\n  &-middle {\n    width: 2rem !important;\n    height: 2rem !important;\n  }\n\n  &-large {\n    width: 3rem !important;\n    height: 3rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner-border-size-small": "LoadingElement__spinner-border-size-small__DmDw",
	"spinner-border-size-middle": "LoadingElement__spinner-border-size-middle__bsMP",
	"spinner-border-size-large": "LoadingElement__spinner-border-size-large__Rsmm"
};
export default ___CSS_LOADER_EXPORT___;
