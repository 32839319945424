import ApiApplication from 'ApiRest/Api/IncomeDiagnostics/Application'
import ApiApplications from 'ApiRest/Api/IncomeDiagnostics/Applications'
import ApiDiagnosticsApplicationsStatuses from 'ApiRest/Api/IncomeDiagnostics/ApplicationsStatuses'
import ApiConfiguration from 'ApiRest/Api/IncomeDiagnostics/Configuration'
import ApiDiagnosticsCreateRetake from 'ApiRest/Api/IncomeDiagnostics/CreateRetake'
import ApiDiagnosticsFailedSubjects from 'ApiRest/Api/IncomeDiagnostics/FailedSubjects'
import ApiSettingsSubjects from 'ApiRest/Api/IncomeDiagnostics/SettingsSubjects'
import ApiUploadTestingFile from 'ApiRest/Api/IncomeDiagnostics/UploadTestingFile'
import ApiOption from 'ApiRest/Api/Option'
import ApiSchoolList from 'ApiRest/Api/School/List'
import ApiTesting from 'ApiRest/Api/Testing/Check/Test'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchApplications({ commit }, params) {
    const response = await ApiApplications.get(params)

    commit('setApplications', response.data)

    return response
  },

  async fetchApplication({ commit }, id) {
    const params = {
      expand:
        'status,school,grade,client,student,invoice,retakes,passingSubjects',
    }

    const { data } = await ApiApplication.get(id, params)

    commit('setApplication', data)
  },

  async fetchSchools({ commit }) {
    const options = {
      hasModule: 'income-diagnostics',
    }

    const { data } = await ApiSchoolList.get(options)

    commit('setSchools', data)
  },

  async fetchServices({ commit }, id) {
    const { data } = await ApiOption.getInvoiceServices(id)

    commit('setOptions', {
      key: 'services',
      data,
    })
  },

  async fetchApplicationsStatuses({ commit }) {
    const {
      data,
    } = await ApiDiagnosticsApplicationsStatuses.getApplicationsStatuses()

    commit('setApplicationStatuses', data)
  },

  async fetchFailedSubjects({ commit }, applicationid) {
    const { data } = await ApiDiagnosticsFailedSubjects.get(applicationid)

    commit('setOptions', {
      key: 'subjects',
      data,
    })
  },

  async createRetake({ commit }, { applicationId, data }) {
    const { data: payload } = await ApiDiagnosticsCreateRetake.post(
      applicationId,
      data,
    )

    commit('addRetake', payload)
  },

  async fetchConfiguration({ commit }, id) {
    const { data } = await ApiConfiguration.get(id)

    commit('setConfiguration', data)
  },

  async fetchGrades({ commit }, schoolId) {
    const parameters = {
      schoolId,
    }

    const { data } = await ApiOption.getGrades(parameters)

    commit('setOptions', {
      key: 'grades',
      data,
    })
  },

  async fetchSettingsSubjects({ commit }, schoolId) {
    const {
      data: { gradeSubjectSettings },
    } = await ApiSettingsSubjects.get(schoolId)

    commit('setSettingsSubjects', gradeSubjectSettings)
  },

  async fetchManagers({ commit }, schoolId) {
    const { data } = await ApiOption.getCtpCheckers(schoolId)

    commit('setManagers', data)
  },

  async saveGradeSubjectSettings({ state, commit }, schoolId) {
    const data = {
      gradeSubjectSettings: state.gradeSubjectSettings,
    }

    const {
      data: { gradeSubjectSettings },
    } = await ApiSettingsSubjects.post(schoolId, data)

    commit('setSettingsSubjects', gradeSubjectSettings)
  },

  async uploadFilesPassingSubject(
    context,
    { applicationId, files, testingId },
  ) {
    const payload = {
      testing: {
        id: testingId,
      },
      files,
    }

    const { data } = await ApiUploadTestingFile.post(applicationId, payload)

    return data
  },

  async fetchTesting(context, testingId) {
    const { data } = await ApiTesting.get(testingId)

    return data
  },
}
