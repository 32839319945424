export const userActions = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',

  READ_LIBRARY_PROOFREADING: 'read_library_proofreading',
  READ_LIBRARY_FIX_REQUIRED: 'read_library_fix_required',
  READ_LIBRARY_MODERATION: 'read_library_moderation',
  READ_LIBRARY_APPROVED: 'read_library_approved',

  READ_STREAMS: 'read_streams',

  READ_MENU_ITEM: 'read_menu_item',

  RUN_BIG_BLUE_BUTTON_SHOWCASE_LESSON: 'showcase_online_lessons_view',

  FULL_UPDATE_ONLINE_LESSONS: 'online_lessons_full_update',

  UPDATE_STATEMENT_MANUAL_MARK: 'update_statement_manual_mark',
  UPDATE_STATEMENT_UPLOAD: 'update_statement_upload',

  // Редактирование количества голосов раздела поддержки
  UPDATE_SUPPORT_VOTE_COUNT: 'update_support_vote_count',
  // Редактирование менеджерских полей раздела поддержка - деталка вопрос
  UPDATE_SUPPORT_QUESTION_MANAGER_FIELDS:
    'update_support_question_manager_fields',
  // Редактирование департаментов раздела поддержки
  UPDATE_SUPPORT_QUESTION_DEPARTMENTS: 'update_support_question_departments',

  // Редактирование признака "Активная школа"
  UPDATE_ACTIVE_SCHOOL: 'update_active_school',

  // Редактирование доступа в Физикон
  // TODO: https://iblshool.atlassian.net/browse/I20-3770?focusedCommentId=17633
  UPDATE_ACCESS_PHYSICON: 'students_physicon',

  // Редактирование признака "Тестовый клиент"
  UPDATE_CLIENT_TEST_FLAG: 'update_client_test_flag',

  // Редактирование признака "Тестовый ученик"
  UPDATE_STUDENT_TEST_FLAG: 'update_student_test_flag',

  // Добавление и удаление модулей школы
  UPDATE_SCHOOL_MODULES: 'update_school_modules',

  // Отправка договора в 1С
  UPDATE_CONTRACT_SEND_1C: 'update_contract_send_1C',

  // Изменение картинки КТП для ЦОК
  UPDATE_CTP_COVER_FILE: 'update_ctp_cover_file',

  // Активация функционала чата
  ENABLE_CHAT: 'enable_chat',
}
