import { ApiAnalyticsCtpDownload } from 'ApiRest/Api/Analytics/Ctp/Download'
import { ApiAnalyticsCtp } from 'ApiRest/Api/Analytics/Ctp/List'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  async fetchCtps({ commit }, parameters) {
    const { data } = await ApiAnalyticsCtp.get(parameters)

    commit('setCtpData', data)
  },

  async downloadAnalyticsCtp(context, parameters) {
    const response = await ApiAnalyticsCtpDownload.get(parameters)

    DownloadHelper.downloadBlob(response)
  },
}
