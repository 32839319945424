import { LocaleController } from 'Helpers/models/LocaleController'

export class LocaleControllerForSchoolManager extends LocaleController {
  constructor({ lang }) {
    super({
      institutionType: 'school',
      userType: 'manager',
      lang,
    })
  }
}
