// Сохранение настроек доменов в window
import 'Helpers/domains'
import '@/plugins/echarts'

import AdminAccessControlRules from '@admin/accessControl/rules'
import AdminRouter from '@admin/AdminRouter'
import AdminStore from '@admin/AdminStore'
import AdminApp from '@admin/App.vue'
import { abilitiesPlugin } from '@casl/vue'
import SentryHelper from 'Helpers/SentryHelper'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueDeprecate from 'vue-deprecate'
import VModal from 'vue-js-modal'
import VueMeta from 'vue-meta'
import Router from 'vue-router'
import VueTippy from 'vue-tippy'
import Toasted from 'vue-toasted'
import Vuelidate from 'vuelidate'
import Vuex from 'vuex'

import { i18n } from '@/i18n/setup'
import { pubSubInit } from '@/pubsub/subscriber'

pubSubInit()
SentryHelper.init()

Vue.config.productionTip = false

if (process.iblsEnv.NODE_ENV === 'production') {
  Vue.config.silent = true
  Vue.config.debug = false
  Vue.config.devtools = false
}

let app

Vue.use(Router)
Vue.use(Vuex)
Vue.use(VueTippy, {
  onShow(options) {
    return Boolean(options.props.content)
  },
})
Vue.use(VueMeta)
Vue.use(VCalendar)
Vue.use(VueDeprecate)
Vue.use(Vuelidate)

const createAppAdmin = () => {
  const router = AdminRouter.init()
  const store = AdminStore.init()

  // TODO: с vue3 заменить на встроенный teleport
  Vue.use(PortalVue)
  Vue.use(VModal, {
    dynamic: true,
  })
  Vue.use(abilitiesPlugin, AdminAccessControlRules.ability)
  Vue.use(Toasted, {
    position: 'top-right',
  })

  app = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(AdminApp),
  })
}

createAppAdmin()

if (!app) {
  throw new Error(`App is not initialized!`)
}

const preload = document.querySelector('#preload')

if (preload) {
  preload.remove()
}

app.$mount('#app')
