import { ApiBanner } from 'ApiRest/Api/Banners/Banner'
import { ApiBannerList } from 'ApiRest/Api/Banners/BannerList'

export default {
  async fetchBanners({ commit }, filters = {}) {
    const { data, headers } = await ApiBannerList.get(filters)

    commit('setFilters', filters)
    commit('setBanners', data)
    commit('setPagination', headers)
  },

  async fetchTheBanner({ commit }, bannerId) {
    const { data } = await ApiBanner.get(bannerId)

    commit('setTheBanner', data)
  },

  async updateTheBanner({ state, commit }, bannerId) {
    const { data } = await ApiBanner.patch(bannerId, state.banner)

    commit('setTheBanner', data)
  },

  removeBanner(context, bannerId) {
    return ApiBanner.delete(bannerId)
  },
}
