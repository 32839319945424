<template>
  <div class="uiKit-formField">
    <div class="d-flex">
      <span v-if="label" class="uiKit-formField-label">
        {{ label }}
        <span v-if="required" class="text-pink">*</span>
      </span>

      <i
        v-if="showEraser"
        class="fas fa-eraser ml-2 uiKit-formField-eraser-icon"
        @click="erase"
      ></i>
    </div>

    <span :class="{ error: errors }" class="uiKit-formField-value">
      <slot></slot>
    </span>

    <UiKitFormFieldError v-show="error">{{ error }}</UiKitFormFieldError>
  </div>
</template>

<script>
import UiKitFormFieldError from '@admin/ui/admin/UiKitFormFieldError.vue'

export default {
  name: 'UiKitFormField',

  components: {
    UiKitFormFieldError,
  },

  props: {
    label: {
      type: String,
      default: '',
    },

    errors: {
      validator: (prop) =>
        typeof prop === 'string' || typeof prop === 'object' || prop === null,

      default: null,
    },

    required: {
      type: Boolean,
      default: false,
    },

    showEraser: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    error() {
      if (typeof this.errors === 'string' || this.errors === null) {
        return this.errors
      }

      if (typeof this.errors === 'object') {
        return this.findDeepestString(this.errors)
      }

      return false
    },
  },

  methods: {
    /**
     * Returns first deepest value from object
     * Ex.: { image: { id: "Необходимо заполнить «Id»." }
     *
     * @param {Object | string} value
     * @returns {string}
     */
    findDeepestString(value) {
      if (typeof value === 'string') {
        return value
      }

      Object.keys(value).forEach((key) => {
        if (value[key]) {
          this.findDeepestString(value[key])
        }
      })

      return ''
    },

    erase() {
      this.$emit('erased')
    },
  },
}
</script>

<style lang="scss">
@import '~@admin/scss/functions';
@import '~@admin/scss/bootstrap/variables';

.uiKit-formField {
  display: block;
  padding: 0;
  margin: 0;

  &-label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--uiKit-formField-labelColor);
    @include font-size(12px);
  }

  &-value {
    display: block;
  }

  // TODO: Временно оставлено для обратной совместимости
  &-error,
  &-warning {
    display: block;
    margin-top: 3px;
    line-height: 16px;
    letter-spacing: 0.01em;
    opacity: 0.7;
    @include font-size(12px);
  }

  // TODO: Временно оставлено для обратной совместимости
  &-error {
    color: $bright-red;
  }

  &-warning {
    color: var(--uiKit-formField-labelColor);
  }

  &-eraser-icon {
    color: var(--uiKit-formField-labelColor);
    cursor: pointer;
  }
}
</style>
