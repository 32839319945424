import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProductMaterialTypes = new (class extends ApiBase {
  /**
   * Типы материалов продукта
   *
   * @returns {Promise}
   */
  get() {
    return this._GET(`/product/options/material-types`)
  }
})()
