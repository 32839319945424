import ApiCheckers from 'ApiRest/Api/Ctp/Checkers'
import ApiAddCustomTest from 'ApiRest/Api/FamilyEducation/AddCustomTest'
import ApiCertificationRequests from 'ApiRest/Api/FamilyEducation/CertificationRequests'
import ApiDraftCertification from 'ApiRest/Api/FamilyEducation/DraftCertification'
import ApiFamilyEducationOptionTest from 'ApiRest/Api/FamilyEducation/Option/Test'
import ApiTheCertificationRequest from 'ApiRest/Api/FamilyEducation/TheCertificationRequest'
import ApiOption from 'ApiRest/Api/Option'
import ApiExaminationLevel from 'ApiRest/Api/School/ExaminationLevels'
import ApiUserStudentStream from 'ApiRest/Api/User/Student/Option/Stream'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchRequests({ commit }, params) {
    const response = await ApiCertificationRequests.get(params)

    commit('setRequests', response.data)

    return response
  },

  async fetchTheRequest({ commit }, requestId) {
    const { data } = await ApiTheCertificationRequest.get(requestId)

    commit('setTheRequest', data)
  },

  async fetchTheRequestDraft({ state, commit }) {
    const params = {
      clientId: state.clientId,
      studentId: state.studentId,
    }

    const { data } = await ApiDraftCertification.get(params)

    commit('setTheRequest', data)
  },

  async createTheRequest({ commit }, dataRequest) {
    const response = await ApiTheCertificationRequest.post(dataRequest)

    commit('setTheRequest', response.data)

    return response
  },

  async updateTheRequest({ commit }, { requestId, payload }) {
    const { data } = await ApiTheCertificationRequest.patch(requestId, payload)

    commit('setTheRequest', data)
  },

  async addCustomTest(context, { requestId, test }) {
    await ApiAddCustomTest.post(requestId, {
      test,
    })
  },

  async fetchOptionSchools({ commit }, params) {
    const response = await ApiOption.getSchools(params)

    commit('setOption', {
      key: 'schools',
      value: response.data,
    })

    return response
  },

  async fetchOptionGrades({ commit }, params) {
    const response = await ApiOption.getGrades(params)

    commit('setOption', {
      key: 'grades',
      value: response.data,
    })

    return response
  },

  async fetchOptionStreams({ commit }, params) {
    const { data } = await ApiUserStudentStream.get(params)

    commit('setOption', {
      key: 'streams',
      value: data,
    })
  },

  async fetchOptionStatuses({ commit }) {
    const response = await ApiOption.getFamilyEducationCertRequestStatus()

    commit('setOption', {
      key: 'statuses',
      value: response.data,
    })

    return response
  },

  async fetchOptionSchedules({ commit }, params) {
    const response = await ApiOption.getFamilyEducationCertSchedule(params)

    commit('setOption', {
      key: 'schedules',
      value: response.data,
    })

    return response
  },

  async fetchOptionSubmissionTypes({ commit }) {
    const {
      data,
    } = await ApiOption.getFamilyEducationCertRequestSubmissionType()

    commit('setOption', {
      key: 'submissionTypes',
      value: data,
    })
  },

  async fetchOptionCitizenships({ commit }) {
    const { data } = await ApiOption.getCitizenships()

    commit('setOption', {
      key: 'citizenships',
      value: data,
    })
  },

  async fetchOptionCountries({ commit }) {
    const { data } = await ApiOption.getCountries()

    commit('setOption', {
      key: 'countries',
      value: data,
    })
  },

  async fetchOptionLegalRepresentativeTypes({ commit }) {
    const { data } = await ApiOption.getLegalRepresentativeTypes()

    commit('setOption', {
      key: 'legalRepresentativeTypes',
      value: data,
    })
  },

  async fetchOptionTests({ commit }, { gradeId, subjectId }) {
    const params = {
      grade: {
        id: gradeId,
      },
      subject: {
        id: subjectId,
      },
    }

    const { data } = await ApiFamilyEducationOptionTest.get(params)

    commit('setOption', {
      key: 'tests',
      value: data,
    })
  },

  async fetchOptionSubjects({ commit }) {
    const { data } = await ApiOption.getSubjects()

    commit('setOption', {
      key: 'subjects',
      value: data,
    })
  },

  async fetchOptionModalGrades({ commit }) {
    const { data } = await ApiOption.getGrades()

    commit('setOption', {
      key: 'modalGrades',
      value: data,
    })
  },

  async fetchTestWhenShowAnswerTypes({ commit }) {
    const { data } = await ApiOption.getCtpTestWhenShowAnswerTypes()

    commit('setOption', {
      key: 'whenShowAnswerTypes',
      value: data,
    })
  },

  async fetchTestShowAnswerTypes({ commit }) {
    const { data } = await ApiOption.getCtpTestShowAnswerTypes()

    commit('setOption', {
      key: 'showAnswerTypes',
      value: data,
    })
  },

  async fetchTestExaminationLevels({ commit }, params) {
    const { data } = await ApiExaminationLevel.get(params.school.id)

    commit('setOption', {
      key: 'ctpTestExaminationLevels',
      value: data,
    })
  },

  async fetchTestAttemptsMarkTypes({ commit }) {
    const { data } = await ApiOption.getCtpTestAttemptsMarkType()

    commit('setOption', {
      key: 'attemptsMarkTypes',
      value: data,
    })
  },

  async fetchTestCheckers({ commit }, params) {
    const { data } = await ApiCheckers.get(params.school.id)

    commit('setOption', {
      key: 'checkers',
      value: data,
    })
  },
}
