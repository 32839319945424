import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setOnlineLessons(state, payload) {
    state.onlineLessons = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setTheOnlineLesson(state, payload) {
    state.theOnlineLesson = payload
  },

  /**
   * @param {Object} state
   * @param {boolean} payload
   */
  setTheOnlineLessonIsRunning(state, payload) {
    state.isOnlineLessonRunning = payload
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {string} args.key
   * @param {number|string|boolean|Array|null} args.value
   */
  setTheOnlineLessonValue(state, { key, value }) {
    Vue.set(state.theOnlineLesson, key, value)
  },

  /**
   * @param {Object} state
   * @param {Object} school
   */
  addTheAffiliation(state, school) {
    const affiliation = {
      school,
      grades: [],
      managers: [],
      subjects: [],
      educationTypes: [],
    }

    state.theOnlineLesson.affiliations.push(affiliation)
    state.theOnlineLesson.affiliations.sort((a, b) => a.school.id - b.school.id)
  },

  /**
   * @param {Object} state
   * @param {Object} school
   */
  removeTheAffiliation(state, school) {
    const schoolIndex = state.theOnlineLesson.affiliations.findIndex(
      (item) => item.school.id === school.id,
    )

    state.theOnlineLesson.affiliations.splice(schoolIndex, 1)
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {number} args.index
   * @param {Object} args.value
   */
  setTheAffiliationValue(state, { index, value }) {
    Vue.set(state.theOnlineLesson.affiliations, index, value)
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {string} args.key
   * @param {Array} args.value
   */
  setOption(state, { key, value }) {
    Vue.set(state.option, key, value)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setTheOnlineLessonAnalytics(state, payload) {
    state.analytics = payload
  },

  /**
   * @param {Object} state
   * @param {number} payload
   */
  setTheOnlineLessonCountByRepeatEndDate(state, payload) {
    state.newRepeatEndDateCount = payload
  },

  /**
   * @param {Object} state
   * @param {string} payload
   */
  setTheOnlineLessonBigBlueButtonLessonUrl(state, payload) {
    state.bigBlueButtonLessonUrl = payload
  },
}
