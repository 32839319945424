var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style["title-index"] }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-items-start justify-content-between",
        class: _vm.$style.header,
      },
      [
        _c("div", [
          _c("h1", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _vm.subTitle
            ? _c("h4", { class: _vm.$style.subTitle }, [
                _vm._v(_vm._s(_vm.subTitle)),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("HeaderInfoBar", { staticClass: "flex-1" }),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }