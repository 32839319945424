const routes = [
  {
    path: '/enrollment-requests',
    name: 'admin-enrollment-requests',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-enrollment-requests" */
        '@admin/pages/admin/request/Requests.vue'
      ),
  },

  {
    path: '/enrollment-request/detail/:enrollmentRequestId(\\d+)',
    name: 'admin-the-enrollment-request',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-enrollment-request" */
        '@admin/pages/admin/request/TheRequest.vue'
      ),
  },

  {
    path: '/enrollment-requests/trash',
    name: 'admin-enrollment-requests-trash',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-enrollment-requests-trash" */
        '@admin/pages/admin/request/RequestsTrash.vue'
      ),
  },

  {
    path: '/enrollment-requests/draft',
    name: 'admin-enrollment-requests-draft',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-enrollment-requests-draft" */
        '@admin/pages/admin/request/RequestsDraft.vue'
      ),
  },

  {
    path: '/enrollment-request-draft/detail/:idRequestDraft(\\d+)',
    name: 'admin-the-enrollment-request-draft',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-enrollment-request-draft" */
        '@admin/pages/admin/request/TheRequestDraft.vue'
      ),
  },
]

export default routes
