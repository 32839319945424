import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiAnalyticsStudentEnrolledSchoolsCost = new (class extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise<Object>}
   */
  get(params = {}) {
    const urlParams = UrlHelper.param(params)
    const url = `/analytic-student/student-enrolled/schools-cost-total?${urlParams}`

    return this._GET(url)
  }
})()
