import ApiCalendarItems from 'ApiRest/Api/CalendarItems'
import { ApiOnlineLesson } from 'ApiRest/Api/OnlineLesson'
import { ApiOnlineLessonAnalytics } from 'ApiRest/Api/OnlineLesson/Analytics'
import { ApiOnlineLessonConferenceBigBlueButton } from 'ApiRest/Api/OnlineLesson/ConferenceBigBlueButton'
import { ApiOnlineLessonCopy } from 'ApiRest/Api/OnlineLesson/Copy'
import { ApiOnlineLessonCountByRepeatEndDate } from 'ApiRest/Api/OnlineLesson/CountByRepeatEndDate'
import { ApiOnlineLessonGroup } from 'ApiRest/Api/OnlineLesson/Group'
import { ApiOnlineLessonIsRunning } from 'ApiRest/Api/OnlineLesson/IsRunning'
import { ApiOnlineLessonOption } from 'ApiRest/Api/OnlineLesson/Option'
import { ApiOnlineLessonRoomList } from 'ApiRest/Api/OnlineLesson/RoomList'
import ApiUserSettings from 'ApiRest/Api/UserSettings'
import { types as calendarTypes } from 'Helpers/Calendar'
import { isArray } from 'lodash'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   */
  resetState({ commit }) {
    commit('resetState')
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOnlineLessons({ commit }, params) {
    const payload = {
      ...params,
      typeIds: [calendarTypes.ONLINE_LESSON],
    }

    const { data } = await ApiCalendarItems.get(payload)

    commit('setOnlineLessons', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   */
  async fetchOptionSchools({ commit }) {
    const { data } = await ApiOnlineLessonOption.getSchools()

    commit('setOption', {
      key: 'schools',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOptionGrades({ commit }, params) {
    const { data } = await ApiOnlineLessonOption.getGrades(params)

    commit('setOption', {
      key: 'grades',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOptionSubjects({ commit }, params) {
    const { data } = await ApiOnlineLessonOption.getSubjects(params)

    commit('setOption', {
      key: 'subjects',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOptionManagers({ commit }, params) {
    const { data } = await ApiOnlineLessonOption.getManagers(params)

    commit('setOption', {
      key: 'managers',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} params
   */
  async fetchOptionEducationTypes({ commit }, params) {
    const { data } = await ApiOnlineLessonOption.getEducationTypes(params)

    commit('setOption', {
      key: 'educationTypes',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   */
  async fetchOptionOnlineLessonRepeatTypes({ commit }) {
    const { data } = await ApiOnlineLessonOption.getOnlineLessonRepeatTypes()

    commit('setOption', {
      key: 'onlineLessonRepeatTypes',
      value: data,
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   */
  async createTheOnlineLesson({ commit }, payload) {
    const params = {
      expand: 'conferenceType',
    }

    const { data } = await ApiOnlineLesson.post(payload, params)

    commit('setTheOnlineLesson', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {Object} args.payload
   */
  async updateTheOnlineLesson({ commit }, { lessonId, payload }) {
    const params = {
      expand: 'conferenceType',
    }

    const { data } = await ApiOnlineLesson.patch(lessonId, payload, params)

    commit('setTheOnlineLesson', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {Object} args.payload
   * @param {boolean} args.isAll
   */
  async updateTheOnlineLessonGroup({ commit }, { lessonId, payload, isAll }) {
    payload.editAll = isAll === true

    const { data } = await ApiOnlineLessonGroup.patch(lessonId, payload)

    commit('setTheOnlineLesson', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {Object} args.payload
   * @returns {Promise}
   */
  copyTheOnlineLesson(context, { lessonId, payload }) {
    return ApiOnlineLessonCopy.put(lessonId, payload)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async fetchTheOnlineLesson({ commit }, lessonId) {
    const { data } = await ApiOnlineLesson.get(lessonId, {
      expand: 'conferenceType',
    })

    commit('setTheOnlineLesson', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async fetchTheOnlineLessonIsRunning({ commit }, lessonId) {
    const { data } = await ApiOnlineLessonIsRunning.get(lessonId)

    commit('setTheOnlineLessonIsRunning', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {number} lessonId
   * @returns {Promise}
   */
  deleteTheOnlineLesson(context, lessonId) {
    return ApiOnlineLesson.delete(lessonId)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {boolean} args.isAll
   */
  async deleteTheOnlineLessonGroup({ commit }, { lessonId, isAll }) {
    const payload = {
      deleteAll: isAll === true,
    }

    const { data } = await ApiOnlineLessonGroup.delete(lessonId, payload)

    commit('setTheOnlineLesson', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async fetchTheOnlineLessonAnalytics({ commit }, lessonId) {
    const { data } = await ApiOnlineLessonAnalytics.get(lessonId)

    commit('setTheOnlineLessonAnalytics', data)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} args
   * @param {number} args.lessonId
   * @param {string|null|undefined} args.newEndDate
   * @param {number} args.repeatTypeId
   */
  async fetchTheOnlineLessonCountByRepeatEndDate(
    { commit },
    { lessonId, newEndDate = null, repeatTypeId },
  ) {
    const { data } = await ApiOnlineLessonCountByRepeatEndDate.get({
      onlineLessonId: lessonId,
      newEndDate,
      repeatTypeId,
    })

    commit('setTheOnlineLessonCountByRepeatEndDate', data.count)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} context.state
   */
  async fetchOptionOnlineLessonRooms({ commit, state }) {
    const filters = {
      rowsPerPage: 200,
    }

    const { lessonDate, timeStart, timeEnd } = state.theOnlineLesson

    if (lessonDate !== '' && timeStart !== '' && timeEnd !== '') {
      filters.lessonDate = lessonDate
      filters.timeStart = `${timeStart}:00`
      filters.timeEnd = `${timeEnd}:00`
    }

    const { data } = await ApiOnlineLessonRoomList.get(filters)

    commit('setOption', {
      key: 'onlineLessonRooms',
      value: data.map(
        ({ id, name, usedInLesson, lessonUrl, additionalInfo }) => {
          let lessonName = name

          if (isArray(usedInLesson) && usedInLesson.length > 0) {
            const lessonIds = usedInLesson.join(', ')

            lessonName = `${name} (Используется в уроках: ${lessonIds})`
          }

          return {
            id,
            name: lessonName,
            lessonUrl,
            additionalInfo,
          }
        },
      ),
    })
  },

  /**
   * @param {Object|undefined} context
   * @param {string} date
   * @returns {Promise}
   */
  saveOnlineLessonsPeriodDate(context, date) {
    return ApiUserSettings.put({
      name: 'online-lessons-period-date',
      data: {
        date,
      },
    })
  },

  /**
   * @returns {string|null}
   */
  async fetchOnlineLessonsPeriodDate() {
    const { data } = await ApiUserSettings.get({
      name: 'online-lessons-period-date',
    })

    return data?.data?.date ?? null
  },

  saveGridView(context, view) {
    return ApiUserSettings.put({
      name: 'online-lessons-grid-view',
      data: {
        view,
      },
    })
  },

  async fetchGridView() {
    const { data } = await ApiUserSettings.get({
      name: 'online-lessons-grid-view',
    })

    return data?.data?.view ?? null
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} context.state
   */
  async fetchBigBlueButtonLessonUrl({ commit, state }) {
    let url
    const { id } = state.theOnlineLesson

    if (state.isOnlineLessonRunning) {
      const { data } = await ApiOnlineLessonConferenceBigBlueButton.get(id)

      url = data
    } else {
      const { data } = await ApiOnlineLessonConferenceBigBlueButton.post(id)

      url = data
    }

    commit('setTheOnlineLessonBigBlueButtonLessonUrl', url)
  },
}
