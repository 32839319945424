const colors = {
  // bootstrap vars

  'app-gray-100': '#f5f5f5',
  'app-gray-300': '#bfc5cf',
  'app-gray-500': '#8d939e',
  'app-gray-600': '#717579',
  'app-gray-700': '#3d444b',
  'app-gray-800': '#33393f',
  'app-gray-900': '#282e33',
  'app-gray-900-darken': '#1c2023',

  'app-pink': '#e71b7d',
  'app-black': '#22242b',
  'app-white': '#ffffff',
  'app-blue': '#0077d7',
  'app-red': '#bc0022',
  'app-green': '#219653',
  'app-bright-red': '#ff1f1f',

  'app-table-bg': '#8d939e',
  'app-table-border-top-color': '#5F6075',
  'app-table-captionBg': '#282e33',
  'app-table-hover-bg': '#3B91D8',
  'app-table-hover-color': '#ffffff',
  'app-table-caption-hover-color': '#ffffff',
  'app-table-commitPen-color': '#717579',
  'app-table-commitCheck-color': '#3d444b',

  'app-modal-content-color': '#C4CFDA',

  'app-modal-content-box-shadow-color': '#202228',

  // custom vars

  'arrowDown-color': '#8d939e',
  'hr-bright': '#8d939e',
  'input-placeholder-color': '#8d939e',
  'input-readonly-color': '#8d939e',

  'question-content-input-group-text-color': '#8d939e',

  'question-content-breakWordsBySyllable-background': '#131618',
  'question-content-breakWordsBySyllable-active': '#004a99',

  'question-content-chooseWord-background': '#131618',
  'question-content-chooseWord-active': '#004a99',

  'question-content-chooseLetter-background': '#131618',
  'question-content-chooseLetter-active': '#004a99',

  'question-content-insertLetterFromList-background': '#131618',
  'question-content-insertLetterFromList-active': '#004a99',

  'question-content-insertWordFromList-background': '#131618',
  'question-content-insertWordFromList-active': '#004a99',

  'question-content-sentencePartsParsing-background': '#131618',
  'question-content-sentencePartsParsing-selectionColor': '#fff',

  'question-content-morphemicParsing-background': '#131618',

  'question-content-morphemicParsing-sign-ending-color': '#f5f5f5',
  'question-content-morphemicParsing-sign-wordstem-color': '#f5f5f5',

  'question-content-dragAndDrop-block-text-color': '#f5f5f5',

  'question-content-dragAndDropColumns-background': '#131618',
  'question-content-dragAndDropColumns-boxShadow-color': '#1f2026',
  'question-content-dragAndDropColumns-columnBorderColor': '#3a4148',

  'question-content-dragAndDropSequence-background': '#131618',
  'question-content-dragAndDropSequence-boxShadow-color': '#1f2026',

  'question-content-insertFromList-background': '#131618',
  'question-content-insertFromList-active': '#004a99',

  'uiKit-checkbox-colorNotChecked': '#8d939e',

  'uiKit-navigation-titleColor': '#8d939e',
  'uiKit-navigation-headerColorInactive': '#f5f5f5',
  'uiKit-navigation-iconColorInactive': '#8d939e',
  'uiKit-navigation-labelColorActive': '#f5f5f5',
  'uiKit-navigation-background': '#3d444b',

  'uiKit-dropWindow-bodyBoxShadow-color': '#202228',
  'uiKit-dropWindow-titleColor': '#8d939e',

  'uiKit-menu-bodyBoxShadow-color': '#202228',
  'uiKit-menu-color': '#8d939e',
  'uiKit-menu-bodyBackground': '#33393f',

  'uiKit-formField-labelColor': '#8d939e',

  'uiKit-upload-big-image-backgroundActive': '#43454a',
  'uiKit-upload-big-toolbar-background': '#2b2d33',

  'uiKit-upload-small-image-background': '#3d444a',
  'uiKit-upload-small-image-backgroundActive': '#43454a',
  'uiKit-upload-small-image-color': '#fff',

  'uiKit-upload-small-audio-background': '#3d444a',
  'uiKit-upload-small-audio-backgroundActive': '#43454a',
  'uiKit-upload-small-audio-color': '#fff',

  'uiKit-dropzone-background': '#1f2026',
  'uiKit-dropzone-borderColor': '#2d2f35',

  'uiKit-tabs-titlesBorderColor': '#404155',
  'uiKit-tabs-activeBorderColor': '#0077d7',
  'uiKit-tabs-activeColor': '#0077d7',
  'uiKit-tabs-inactiveColor': '#d0d4dc',

  'uiKit-contentGroup-boxShadow-color': '#202227',
  'uiKit-contentGroup-boxShadow-inset-color': '#2d2f35',
  'uiKit-contentGroup-boxShadowHover-color': '#1d1e23',

  // plugins

  'multiselectBackground-color': '#33393f',
  'multiselectMenuShadow-color': '#202228',
  'multiselect-menu-placeholder-color': '#8d939e',
  'multiselect-arrow-color': '#8d939e',

  'draggable-tree-cellBackground': '#33393f',
  'draggable-tree-cellHover': '#3d444b',
  'draggable-tree-cellColor': '#f5f5f5',
  'draggable-inputIcon-color': '#8d939e',
  'draggable-tree-placeHolderBorder': '#0088f8',
  'draggable-tree-placeHolderBackground': '#1f2d3d',
  'draggable-tree-placeHolderColor': '#8d939e',
  'draggable-tree-placeHolderInnerBackground': '#ddd',
  'draggable-tree-placeHolderInner2Background': '#ccc',

  'datepickerCalendarBg-color': '#282e33',
  'datepickerCalendarInputBackground-color': '#33393f',
  'datepickerCalendarInputIcons-color': '#8d939e',
  'datepickerCalendarButtonBg-color': '#3d444b',
  'datepickerCalendarSelected-color': '#3d444b',
  'datepickerCalendarHeaderButtonBg-color': '#282e33',

  'wysiwyg-backgroundColor': '#fff',
  'wysiwyg-color': '#000',

  'tree-select-valueBackground': '#41b883',
  'tree-select-valueRemoveColor': '#266d4d',

  'toasted-error-border-color': '#fff',
  'toasted-error-color': '#fff',

  // components

  'components-pagination-linkColor': '#f5f5f5',
  'components-pagination-active-linkColor': '#f5f5f5',
  'components-pagination-borderColor': '#dee2e6',

  'components-compositeBootstrapTable-hoverColor': '#e4e3e3',

  'components-compositeTable-chosenBackground': '#3b91d8',

  'compositeFilters-background-color': '#3d444b',
  'compositeFilters-titleColor': '#8d939e',
  'compositeFilters-labelColor': '#8d939e',
  'compositeSearch-placeholder-color': '#8d939e',
  'compositeSearch-iconSearch-color': '#8d939e',

  'box-title-color': '#f5f5f5',
  'box-element-white-text': '#ffffff',
  'box-element-black-text': '#282e33',

  'cellTooltip-labelColor': '#8d939e',
  'cellTooltip-tooltipColor': '#ffffff',
  'cellTooltip-tooltipBackground-color': '#3d444b',
  'cellTooltip-countBoxShadowColor': '#223a32',

  'warning-text': '#664300',
  'warning-background': '#ffdc99',
  'warning-border': '#ffdc99',

  // ui

  'ui-uiKitMenu-selectedBorder': '#5f6075',

  'ui-uiKitNavigation-notifyColor': '#fff',

  // page

  'page-certificationStatement-border': '#3d444b',

  'page-clientDetail-tooltipCount-boxShadowColor': '#223a32',

  'page-ctpExtramuralDetail-topicDateColor': '#fff',
  'page-ctpExtramuralDetail-topicDateErrorBackground': '#ac1d04',

  'page-onlineLessonIndex-inputBackground': '#33393f',

  'page-onlineLessonDetail-ldsEllipsis': '#fff',

  'page-roleIndex-ldsEllipsis': '#fff',

  'page-roleDetail-ldsEllipsis': '#fff',

  'page-userDetail-treeSelectBackground': '#3d444b',
  'page-userDetail-treeSelectArrow-color': '#8d939e',
  'page-userDetail-ldsEllipsis': '#fff',

  'page-userIndex-ldsEllipsis': '#fff',

  'page-statementDetail-tableDisabledBackground': '#1c1e23',
  'page-statementDetail-tableTrHoverLinkColor': '#adff2f',
  'page-statementDetail-tableFirstTdBackground': '#8d939e',
  'page-statementDetail-tableTrHoverBackground': '#3b91d8',
  'page-statementDetail-tableTrHoverPeriodBackground': '#3387cc',
  'page-statementDetail-tableTrHoverYearBackground': '#2874b3',
  'page-statementDetail-tableTdBorder': '#5f6075',
  'page-statementDetail-attemptBorder': '#7b7b7b',

  'page-groupDistribution-cellBackground': '#33393f',
  'page-groupDistribution-borderColor': '#282e33',
  'page-groupDistribution-color': '#f5f5f5',
  'page-groupDistribution-captionColor': '#8d939e',
  'page-groupDistribution-labelColor': '#ddd',
  'page-groupDistribution-nameColor': '#c4c4c4',
  'page-groupDistribution-searchColor': '#939393',
  'page-groupDistribution-searchPlaceholderColor': '#565656',

  // ag-grid

  'ag-grid-border-color': '#424242',

  // full-time-statement

  'fts-table-th-field-active-color': '#f5f5f5',
  'fts-table-th-select-option-bg-color': '#2d3436',
  'fts-btn-color': 'rgba(245, 245, 245, 0.64)',
  'fts-btn-bg-color': '#2d3436',
  'fts-info-bg-color': '#2d3436',
  'fts-info-table-caption-color': '#332f3e',
  'fts-sidebar-wrapper-bg-color': '#2d3436',

  'is-test': '#747881',
  'has-price-error': '#8e252c',
}

export default colors
