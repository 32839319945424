import { render, staticRenderFns } from "./UiKitFormField.vue?vue&type=template&id=71073202&"
import script from "./UiKitFormField.vue?vue&type=script&lang=js&"
export * from "./UiKitFormField.vue?vue&type=script&lang=js&"
import style0 from "./UiKitFormField.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev-beta-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71073202')) {
      api.createRecord('71073202', component.options)
    } else {
      api.reload('71073202', component.options)
    }
    module.hot.accept("./UiKitFormField.vue?vue&type=template&id=71073202&", function () {
      api.rerender('71073202', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "webpack/@admin/ui/admin/UiKitFormField.vue"
export default component.exports