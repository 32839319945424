var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("LoaderFullScreen")
    : _c("div", { class: _vm.$style.page }, [
        _c("div", { staticClass: "py-3", class: _vm.$style.container }, [
          _c(
            "div",
            { staticClass: "px-3", class: _vm.$style.sidebar },
            [
              _c("UiKitNavigation"),
              _vm._v(" "),
              _c(
                "UiKitButton",
                {
                  staticClass: "btn-lg btn-primary mt-4 w-100",
                  on: { click: _vm.onClickBtnLogout },
                },
                [
                  _c("span", { staticClass: "mr-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("layouts.admin_main.exit")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "fa fa-sign-out-alt" }),
                ]
              ),
              _vm._v(" "),
              _c("portal-target", { attrs: { name: "left" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "px-3", class: _vm.$style.content },
            [
              _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
                _c("main", { class: _vm.$style.main }, [_c("router-view")], 1),
              ]),
            ],
            1
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }