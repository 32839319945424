import ApiOption from 'ApiRest/Api/Option'
import { ApiSupportDepartment } from 'ApiRest/Api/Support/Department/Department'
import { ApiSupportDepartments } from 'ApiRest/Api/Support/Department/List'

export default {
  async fetchSystemDepartments({ commit }) {
    const parameters = {
      expand: 'members',
      isSystem: true,
      editable: true,
    }

    const { data } = await ApiSupportDepartments.get(parameters)

    commit('setDepartments', {
      path: 'system',
      value: data,
    })
  },

  async fetchCommonDepartments({ state, commit }) {
    const params = {
      school: {
        id: state.optionValues.school.id,
      },
      expand: 'members',
      isSystem: false,
      editable: true,
    }

    const { data } = await ApiSupportDepartments.get(params)

    commit('setDepartments', {
      path: 'common',
      value: data,
    })
  },

  async createTheDepartment({ commit }, payload) {
    const params = {
      expand: 'members',
    }

    const { data } = await ApiSupportDepartment.post(payload, params)

    commit('addTheDepartment', data)
  },

  async updateTheDepartment({ commit }, { departmentId, payload }) {
    const params = {
      expand: 'members',
    }

    const { data } = await ApiSupportDepartment.patch(
      departmentId,
      payload,
      params,
    )

    if (payload.isSystem) {
      commit('setTheDepartment', {
        path: 'system',
        value: data,
      })
    } else {
      commit('setTheDepartment', {
        path: 'common',
        value: data,
      })
    }
  },

  async removeTheDepartment({ commit }, departmentId) {
    await ApiSupportDepartment.delete(departmentId)

    commit('removeTheDepartment', departmentId)
  },

  async fetchSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOption', {
      path: 'schools',
      value: data,
    })
  },

  async fetchCommonManagers({ state, commit }) {
    const params = {
      school: {
        id: state.optionValues.school.id,
      },
    }

    const { data } = await ApiOption.getManagers(params)

    commit('setOption', {
      path: 'managers',
      value: data,
    })
  },

  async fetchSystemManagers({ commit }) {
    const { data } = await ApiOption.getManagers()

    commit('setOption', {
      path: 'systemManagers',
      value: data,
    })
  },
}
