import { AnalyticsStudentEnrolledRowsPerPage } from '@admin/const/AnalyticsStudentEnrolledRowsPerPage'
import { Pagination } from '@admin/helpers/admin/CompositePagination'
import { RESET_STATE } from 'Helpers/ResetState'
import { cloneDeep, isNil, merge, pick } from 'lodash'

import createState from './state'

/**
 * @param {Object} args
 * @param {Object} args.state
 * @param {Array} args.list
 * @param {boolean} args.mergeValues
 * @param {Function} args.formatItem
 * @returns {Object}
 * @private
 */
const getStudentEnrolledAnalyticsFromResponse = function ({
  state,
  list,
  mergeValues = false,
  formatItem = (item) => item,
}) {
  const analytics = {}

  list.forEach((item) => {
    const yearMonth = item.period

    if (isNil(analytics[yearMonth])) {
      analytics[yearMonth] = []
    }

    analytics[yearMonth].push(formatItem(item))
  })

  if (mergeValues) {
    return merge(cloneDeep(state), analytics)
  }

  return analytics
}

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} data
   */
  setStudentEnrolledAnalyticsSchools(state, data) {
    // TODO: в будущем могут добавить выборку по нескольким школам,
    //  тогда надо будет группировать по школе, а не месяцу
    state.studentEnrolled.analytics.schools = getStudentEnrolledAnalyticsFromResponse(
      {
        state: state.studentEnrolled.analytics.schools,
        list: data,
        mergeValues: false,
        formatItem(item) {
          return pick(item, ['students', 'cost', 'school'])
        },
      },
    )
  },

  /**
   * @param {Object} state
   * @param {number} value
   */
  setStudentEnrolledAnalyticsCost(state, value) {
    state.studentEnrolled.analytics.cost = value
  },

  /**
   * @param {Object} state
   * @param {Array} data
   */
  setStudentEnrolledAnalyticsStudents(state, data) {
    state.studentEnrolled.analytics.students = getStudentEnrolledAnalyticsFromResponse(
      {
        state: state.studentEnrolled.analytics.students,
        list: data,
        mergeValues: true,
        formatItem({ student: { id, name }, enrollments }) {
          return {
            id,
            name,
            enrollments,
          }
        },
      },
    )
  },

  /**
   * @param {Object} state
   * @param {Object} data
   * @param {Array} data.students
   * @param {Object} data.filters
   */
  setStudentEnrolledFilters(state, { students, filters }) {
    const filtersCached = {}

    students.forEach(({ period }) => {
      if (isNil(filtersCached[period])) {
        filtersCached[period] = filters
      }
    })

    state.studentEnrolled.filters = merge(
      cloneDeep(state.studentEnrolled.filters),
      filtersCached,
    )
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {Array} args.data
   * @param {Object} args.headers
   */
  setStudentEnrolledPagination(state, { data, headers }) {
    const compositePagination = new Pagination({
      rowsPerPage: AnalyticsStudentEnrolledRowsPerPage,
    })

    compositePagination.updateFromHeaders(headers)

    const pagination = {}

    data.forEach(({ period }) => {
      if (isNil(pagination[period])) {
        pagination[period] = compositePagination

        const paginationState = state.studentEnrolled.pagination?.[period]

        if (paginationState !== undefined) {
          pagination[period].setRowsPerPage(paginationState.rowsPerPage)
        }
      }
    })

    state.studentEnrolled.pagination = merge(
      cloneDeep(state.studentEnrolled.pagination),
      pagination,
    )
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {string} args.period
   * @param {number} args.value
   */
  setStudentEnrolledPaginationRowsPerPage(state, { period, value }) {
    state.studentEnrolled.pagination[period].setRowsPerPage(value)
  },
}
