import { ApiProductManagementParameter } from 'ApiRest/Api/Products/Parameters/Parameter'
import { ApiProductManagementParametersList } from 'ApiRest/Api/Products/Parameters/ParametersList'

export default {
  async fetchParameters({ commit }, filters = {}) {
    const { data, headers } = await ApiProductManagementParametersList.get(
      filters,
    )

    commit('setParameters', data)
    commit('setPagination', headers)
  },

  removeParameter(context, parameterId) {
    return ApiProductManagementParameter.delete(parameterId)
  },
}
