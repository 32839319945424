import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export default new (class extends ApiBase {
  /**
   * @param {{}} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(
      `/family-certification-requests/get-draft?${UrlHelper.param(parameters)}`,
    )
  }
})()
