<template>
  <multiselect
    :label="name"
    :placeholder="isPlaceholder"
    :allow-empty="allowEmpty"
    :show-labels="false"
    :track-by="id"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span slot="noResult">{{ noResultText }}</span>
    <span slot="noOptions">{{ noOptionsText }}</span>
  </multiselect>
</template>

<script>
import multiselect from 'vue-multiselect'

import { i18n } from '@/i18n/setup'

export default {
  name: 'MultiselectRu',

  components: {
    multiselect,
  },

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      default: 'name',
    },

    placeholder: {
      type: String,
      default: '',
    },

    allowEmpty: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: 'id',
    },

    noResult: {
      type: String,
      default: '',
    },

    noOptions: {
      type: String,
      default: '',
    },
  },

  computed: {
    isPlaceholder() {
      return this.placeholder
        ? this.placeholder
        : i18n.t('ui.props_default.placeholder')
    },

    noResultText() {
      return this.noResult
        ? this.noResult
        : i18n.t('ui.props_default.no_result')
    },

    noOptionsText() {
      return this.noOptions
        ? this.noOptions
        : i18n.t('ui.props_default.list_is_empty')
    },
  },
}
</script>
