import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProductCategorySort = new (class extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  patch(data) {
    return this._PATCH('/product/category/sort', data)
  }
})()
