<template>
  <div>
    <label :class="$style.switch">
      <input
        type="checkbox"
        :disabled="disabled"
        :checked="checked"
        @change="onChange"
      />
      <span :class="[$style.slider, $style.round]"></span>
    </label>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'UiKitToggleSwitch',

  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    checked: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onChange(e) {
      this.$emit('switch', e.target.checked)
    },
  },
})
</script>

<style module lang="scss">
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: block;
  width: 52px;
  height: 24px;
  margin-bottom: 0;

  /* Hide default HTML checkbox */
  input {
    display: none;
  }
}

/* The slider */
.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: inherit;
  transition: 0.4s;

  &::before {
    position: absolute;
    bottom: 3px;
    left: 4px;
    width: 16px;
    height: 16px;
    content: '';
    transition: 0.4s;
  }
}

input:checked + .slider::before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border: 1px solid #0d47a1;
  border-radius: 34px;

  &::before {
    background-color: #0077d7;
    border-radius: 50%;
  }
}
</style>
