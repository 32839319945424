export default () => ({
  requests: [],

  theRequest: {
    id: null,
    clientLastName: '',
    clientFirstName: '',
    clientMiddleName: '',
    clientBirthDate: '',
    citizenship: null,
    country: null,
    passSeries: '',
    passNumber: '',
    passIssuedBy: '',
    passIssuedByCode: '',
    passDateIssue: '',
    passValidUntil: null,
    regAddress: '',
    postalAddress: '',
    studentLastName: '',
    studentFirstName: '',
    studentMiddleName: '',
    studentBirthDate: '',
    birthCertificateCitizenship: null,
    birthCertificateSeries: '',
    birthCertificateNumber: '',
    studentRegAddress: '',
    studentRegAddressConfirmDocFile: null,
    birthCertificateDateIssue: '',
    trackingNumber: null,
    logisticsCompanyName: null,
    dateEnd: '',
    number: '',
    status: null,
    isAis: null,
    client: null,
    student: null,
    certificateScanFile: null,

    certification: {
      id: null,
      name: '',
      school: null,
      grade: null,
      status: null,
      tests: [],
    },

    schedule: null,
    legalRepresentativeType: null,
    submissionType: null,
    clientPassportMainPageFile: null,
    studentBirthCertificateFile: null,
    studentPassportMainPageFile: null,
    tests: [],
    contractDocument: null,
    applicationDocument: null,
  },

  option: {
    schools: [],
    grades: [],
    streams: [],
    statuses: [],
    schedules: [],
    submissionTypes: [],
    citizenships: [],
    countries: [],
    legalRepresentativeTypes: [],
    subjects: [],
    modalGrades: [],
    tests: [],
    whenShowAnswerTypes: [],
    showAnswerTypes: [],
    ctpTestExaminationLevels: [],
    attemptsMarkTypes: [],
    checkers: [],
  },

  clientId: null,
  studentId: null,
})
