var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex",
      class: "justify-content-" + _vm.alignX + " align-items-" + _vm.alignY,
    },
    [
      _c(
        "div",
        {
          staticClass: "spinner-border spinner-border-lg text-center",
          class: _vm.$style["spinner-border-size-" + _vm.size],
          style: { color: _vm.color },
          attrs: { role: "status" },
        },
        [
          _c("span", { staticClass: "sr-only" }, [
            _vm._v(_vm._s(_vm.$t("ui.loading"))),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.canShowText
        ? _c("span", { staticClass: "ml-3" }, [_vm._v(_vm._s(_vm.loaderText))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }