export default {
  hasLogs(state) {
    return state.logs.length > 0
  },

  logsListTypes(state) {
    const logs = {
      sendingDocumentLog: [],
      sendingNotificationRequestLog: [],
      creatingRequestLog: [],
      renewedContractLog: [],
      massRenewedContractLog: [],
    }

    state.logs.forEach((log) => {
      if (log.isSendingDocumentLog) {
        logs.sendingDocumentLog.push(log)
      }

      if (log.isSendingNotificationRequestLog) {
        logs.sendingNotificationRequestLog.push(log)
      }

      if (log.isCreatingRequestLog) {
        logs.creatingRequestLog.push(log)
      }

      if (log.isRenewedContractLog) {
        logs.renewedContractLog.push(log)
      }

      if (log.isMassRenewedContractLog) {
        logs.massRenewedContractLog.push(log)
      }
    })

    return logs
  },

  lastLog(state, getters) {
    const isSendingDocumentLog =
      getters.hasLogs && getters.logsListType?.sendingDocumentLog?.length

    if (isSendingDocumentLog) {
      return getters.logsListType?.sendingDocumentLog[0]
    }

    return null
  },

  getLastRequestConfirmLog(state) {
    if (state.lastRequestConfirmLog?.timestamp) {
      return `(${state.lastRequestConfirmLog?.timestamp})`
    }

    return null
  },
}
