import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Детальная информация домашнего задания
   *
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/homework/${id}`)
  }

  /**
   * Поставить оценку домашнему заданию
   *
   * @param {number} id
   * @param {Object} parameters
   * @returns {Promise}
   */
  put(id, parameters = {}) {
    return this._PUT(`/homework/${id}`, parameters)
  }

  /**
   * Изменить проверяющего
   *
   * @param {number} id
   * @param {Object} parameters
   * @returns {Promise}
   */
  patch(id, parameters = {}) {
    return this._PATCH(`/homework/${id}`, parameters)
  }
})()
