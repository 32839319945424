import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param applicationId
   * @returns {Promise}
   */
  get(applicationId) {
    return this._GET(
      `/diagnostics/applications/${applicationId}/failed-income-diagnostics-subjects`,
    )
  }
})()
