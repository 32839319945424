import AdminRouter from '@admin/AdminRouter'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiKeycloak from 'ApiRest/Api/Keycloak'
import ApiUserStudent from 'ApiRest/Api/User/Student'
import Vue from 'vue'

const processError = (message) => {
  Vue.toasted.show(message, {
    type: 'error',
    duration: 3000,
  })
}

const processSuccess = (message) => {
  Vue.toasted.show(message, {
    type: 'success',
    duration: 3000,
  })
}

export default {
  async fetchTheUserIBLS({ commit }, studentId) {
    try {
      const { data } = await ApiUserStudent.get(studentId)

      commit('setTheUserIBLS', data)
    } catch (error) {
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        const message = 'Пользователь не найден'

        AdminRouter.router.push({
          name: 'admin-administrating-zoom',
        })
        processError(message)
      }
    }
  },

  async fetchTheUserZoom({ commit }, userId) {
    try {
      const { data } = await ApiKeycloak.get(userId)

      if (data) {
        commit('setTheUserZoom', data)
      } else {
        const message = 'Нет данных по пользователю в keycloak'

        processError(message)
      }
    } catch (error) {
      const message = error?.response?.data?.message

      processError(message)
    }
  },

  async updateTheUserZoom({ commit }, userId) {
    try {
      const { data } = await ApiKeycloak.patch(userId)

      commit('setTheUserZoom', data)
    } catch (error) {
      const message = error?.response?.data?.message

      processError(message)
    }
  },

  async createTheUserZoom(context, userId) {
    try {
      const { data } = await ApiKeycloak.post(userId)

      if (data === false) {
        const message = 'Операция не была завершена'

        processError(message)
      } else if (data === true) {
        const message = 'Операция успешно завершена'

        processSuccess(message)
      }
    } catch (error) {
      const message = error?.response?.data?.message

      processError(message)
    }
  },
}
