import Constants from 'Constants'

export default {
  /**
   *
   * @param {Object} state
   * @returns {boolean}
   */
  isBigBlueButtonLesson(state) {
    return (
      state.theOnlineLesson.conferenceType ===
      Constants.conferenceType.BIG_BLUE_BUTTON
    )
  },
}
