import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonCopy = new (class extends ApiBase {
  /**
   * Копирование урока
   *
   * @param {number} onlineLessonId
   * @param {Object} payload
   * @returns {Promise}
   */
  put(onlineLessonId, payload) {
    return this._PUT(`/online-lessons/${onlineLessonId}/copy`, payload)
  }
})()
