import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiProductManagement = new (class extends ApiBase {
  /**
   * Получение данных об определённом продукте
   *
   * @param {number} productId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(productId, parameters) {
    return this._GET(`/product/${productId}?${UrlHelper.param(parameters)}`)
  }

  /**
   * Создание продукта
   *
   * @param {Object} data
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(data, parameters) {
    return this._POST(`/product?${UrlHelper.param(parameters)}`, data)
  }

  /**
   * Изменение продукта
   *
   * @param {number} id
   * @param {Object} data
   * @param {Object} parameters
   * @returns {Promise}
   */
  put(id, data, parameters) {
    return this._PUT(`/product/${id}?${UrlHelper.param(parameters)}`, data)
  }
})()
