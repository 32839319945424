export default () => ({
  schools: [],
  applications: [],
  application: {
    grade: null,
    school: null,
    enrollmentContractId: null,
    id: null,
    client: null,
    student: null,
    invoice: null,
    passingSubjects: [],
    retakes: [],
  },

  applicationStatuses: [],
  gradeSubjectSettings: [],
  options: {
    subjects: [],
    services: [],
    grades: [],
  },

  configuration: {
    grades: [],
    timingSystemLink: null,
  },

  managers: [],
})
