export default {
  /**
   * Список корневых родительских групп с потомками
   *
   * @param {Object} state
   * @returns {Array}
   */
  topicsWithItemsFiltered(state) {
    if (state.topicsWithItems.length > 0) {
      return state.topicsWithItems
    }

    return state.topicsWithItems.filter((item) => item.items?.length)
  },
}
