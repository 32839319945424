import ApiBase from 'ApiRest/ApiBase'
import convertEmptyDataToDefaultValue from 'Helpers/ConvertEmptyDataToDefaultValue'
import { cloneDeep, isNil } from 'lodash'

export default new (class Student extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/user/student/${id}`)
  }

  post(data) {
    const isDataEmpty = Object.keys(data).length === 0
    let dataNormalized = {}

    if (!isDataEmpty) {
      dataNormalized = cloneDeep(data)
      convertEmptyDataToDefaultValue(dataNormalized)

      // Обрабатываем московскую регистрацию
      ApiBase.normalizeMoscowRegistration(dataNormalized)

      if (dataNormalized.citizenship) {
        dataNormalized.passport = ApiBase.normalizePassport(
          dataNormalized.passport,
          dataNormalized.citizenship.id,
        )
      }
    }

    return this._POST(`/user/students`, !isDataEmpty ? dataNormalized : data)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    const isDataEmpty = Object.keys(data).length === 0
    let dataNormalized = {}

    if (!isDataEmpty) {
      dataNormalized = cloneDeep(data)
      convertEmptyDataToDefaultValue(dataNormalized)

      // Обрабатываем московскую регистрацию
      ApiBase.normalizeMoscowRegistration(dataNormalized)

      if (dataNormalized.citizenship) {
        dataNormalized.passport = ApiBase.normalizePassport(
          dataNormalized.passport,
          dataNormalized.citizenship.id,
        )
      }

      if (isNil(dataNormalized.watchers)) {
        dataNormalized.watchers = []
      }

      if (isNil(dataNormalized.groupRisk)) {
        dataNormalized.groupRisk = {
          comment: '',
          groups: [],
        }
      }

      if (isNil(dataNormalized.groupRisk.groups)) {
        dataNormalized.groupRisk.groups = []
      }

      if (isNil(dataNormalized.groupSets)) {
        dataNormalized.groupSets = []
      }
    }

    return this._PATCH(
      `/user/student/${id}`,
      !isDataEmpty ? dataNormalized : data,
    )
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  put(id, data) {
    return this._PUT(`/user/student/${id}/watchers`, data)
  }

  /**
   * Удаление ученика в корзину
   *
   * @param {number} id
   * @returns {Promise}
   */
  delete(id) {
    return this._DELETE(`/user/student/${id}`)
  }
})()
