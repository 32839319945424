import AdminRouter from '@admin/AdminRouter'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiOption from 'ApiRest/Api/Option'
import PromocodeGroup from 'ApiRest/Api/PromocodeGroup'
import ApiPromocodeGroupCheckExists from 'ApiRest/Api/PromocodeGroup/CheckExists'
import ApiPromocodeGroupCode from 'ApiRest/Api/PromocodeGroup/Code'
import ApiPromocodeGenerate from 'ApiRest/Api/PromocodeGroup/Generate'
import PromocodeGroupList from 'ApiRest/Api/PromocodeGroup/List'
import ApiPromocodeGroupType from 'ApiRest/Api/PromocodeGroup/Option/Type'
import Vue from 'vue'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchPromocodeGroups({ commit }, params) {
    let response

    try {
      response = await PromocodeGroupList.get(params)

      commit('setPromocodeGroups', response.data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.data?.message) {
        Vue.toasted.show(error.response.data.message, {
          type: 'error',
        })
      } else {
        throw error
      }
    }

    return response
  },

  async fetchPromocodeGroupTypes({ commit }, params) {
    try {
      const { data } = await ApiPromocodeGroupType.get(params)

      commit('setPromocodeGroupTypesValue', data)
    } catch (error) {
      // TODO: перенести в компонент
      if (error?.response?.data?.message) {
        Vue.toasted.show(error.response.data.message, {
          type: 'error',
        })
      } else {
        throw error
      }
    }
  },

  async fetchSchools({ commit }, params) {
    try {
      const { data } = await ApiOption.getSchools(params)

      commit('setSchools', data)
    } catch (error) {
      // TODO: перенести в компонент
      if (error.response.data?.message) {
        Vue.toasted.show(error.response.data.message, {
          type: 'error',
        })
      } else {
        throw error
      }
    }
  },

  async fetchPartners({ commit }, params) {
    try {
      const { data } = await ApiOption.getPartners(params)

      commit('setPartners', data)
    } catch (error) {
      // TODO: перенести в компонент
      if (error.response.data?.message) {
        Vue.toasted.show(error.response.data.message, {
          type: 'error',
        })
      } else {
        throw error
      }
    }
  },

  async fetchServices({ commit }, params) {
    try {
      const { data } = await ApiOption.getInvoiceServices(params)

      commit('setServices', data)
    } catch (error) {
      // TODO: перенести в компонент
      if (error.response.data?.message) {
        Vue.toasted.show(error.response.data.message, {
          type: 'error',
        })
      } else {
        throw error
      }
    }
  },

  async deletePromocodeGroups(context, id) {
    await PromocodeGroup.delete(id)
  },

  async fetchPromocodeGroup({ commit }, { promocodeGroupId, params }) {
    try {
      const { data } = await PromocodeGroup.get(promocodeGroupId, params)

      commit('setPromocodeGroup', data)
    } catch (error) {
      // TODO: перенести в компонент
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        AdminRouter.router.push({
          name: 'admin-payment-promocode-groups',
        })
      }

      if (error?.response?.data?.message) {
        Vue.toasted.show(error.response.data.message, {
          duration: 3000,
          type: 'error',
        })
      } else {
        throw error
      }
    }
  },

  async createPromocodeGroup({ commit }, payload) {
    const { data } = await PromocodeGroup.post(payload)

    commit('setPromocodeGroup', data)
  },

  async fetchPromocodeGroupUniquePromocode({ commit }, payload) {
    const { data } = await ApiPromocodeGroupCheckExists.post(payload)

    commit('setPromocodeGroupUniquePromocode', data)
  },

  async updatePromocodeGroup({ commit }, { promocodeGroupId, promocodeGroup }) {
    const { data } = await PromocodeGroup.patch(
      promocodeGroupId,
      promocodeGroup,
    )

    commit('setPromocodeGroup', data)
  },

  async deletePromocodeGroupCode(
    context,
    { promocodeGroupId, promocodeGroupCodeId },
  ) {
    await ApiPromocodeGroupCode.delete(promocodeGroupId, promocodeGroupCodeId)
  },

  async fetchDiscountTypes({ commit }) {
    const { data } = await ApiOption.getDiscountTypes()

    commit('setDiscountTypes', data)
  },

  async fetchGeneratedPromocode({ commit }) {
    const { data } = await ApiPromocodeGenerate.get()

    commit('setPromocodeGroupValue', {
      key: 'code',
      value: data,
    })
  },
}
