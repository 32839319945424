var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("UiKitToggleSwitch", {
        attrs: { checked: _vm.isDark },
        on: { switch: _vm.switchTheme },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }