import ApiBase from 'ApiRest/ApiBase'

export const ApiProductCertificateRevoke = new (class extends ApiBase {
  /**
   * Отзыв сертификата
   *
   * @param {Object} payload
   * @param {Object} payload.product
   * @param {number} payload.product.id
   * @param {Object} payload.user
   * @param {number} payload.user.id
   * @returns {Promise}
   */
  delete({ product: { id: productId }, user: { id: userId } }) {
    const url = `/product/certificate-issue/revoke?product[id]=${productId}&user[id]=${userId}
`

    return this._DELETE(url)
  }
})()
