import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonAnalytics = new (class extends ApiBase {
  /**
   * Аналитика по уроку
   *
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  get(onlineLessonId) {
    return this._GET(`/online-lessons/analytics/${onlineLessonId}`)
  }
})()
