<template>
  <div :class="$style['title-index']">
    <div
      :class="$style.header"
      class="d-flex align-items-start justify-content-between"
    >
      <div>
        <h1>{{ title }}</h1>
        <h4 v-if="subTitle" :class="$style.subTitle">{{ subTitle }}</h4>
      </div>

      <HeaderInfoBar class="flex-1" />
    </div>
    <hr />
  </div>
</template>

<script>
import HeaderInfoBar from '@admin/components/admin/HeaderInfoBar.vue'

export default {
  name: 'PageTitle',

  components: {
    HeaderInfoBar,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subTitle: {
      type: String,
      default: '',
    },
  },

  watch: {
    title() {
      this.setPageTitle()
    },

    subTitle() {
      this.setPageTitle()
    },
  },

  mounted() {
    this.setPageTitle()
  },

  methods: {
    // TODO в будущем это должно делаться на уровне vue-router
    setPageTitle() {
      let pageTitle = ''

      if (this.title) {
        pageTitle += this.title
      }

      if (this.subTitle) {
        pageTitle += ` - ${this.subTitle}`
      }

      if (pageTitle) {
        document.title = pageTitle
      }
    },
  },
}
</script>

<style module lang="scss">
@import '~bootstrap/scss/mixins';
@import '~@admin/scss/functions';
@import '~@admin/scss/bootstrap/variables';

.title-index {
  .subTitle {
    position: relative;
    top: -9px;
  }
}

.header {
  flex-wrap: nowrap;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    align-items: stretch;
  }
}
</style>
