export default () => ({
  departments: {
    system: [],
    common: [],
  },

  options: {
    schools: [],
    managers: [],
    systemManagers: [],
  },

  optionValues: {
    school: null,
  },
})
