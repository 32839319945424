var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VirtualStyle", [
    _vm._v(
      "\n  " + _vm._s(_vm.colorsDark) + "\n  " + _vm._s(_vm.colorsLight) + "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }