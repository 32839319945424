import { permissionToRules } from '@admin/accessControl/mappers'
import { userActions } from '@admin/accessControl/userActions'
import { Ability, AbilityBuilder } from '@casl/ability'

export default new (class Rules {
  constructor() {
    this.abilityBuilder = new AbilityBuilder(Ability)

    /**
     * @public
     */
    this.ability = this.abilityBuilder.build({
      /**
       * @param {Object} object - (vue-router)
       * @returns {string}
       */
      detectSubjectType: (object) => object.name,
    })
  }

  /**
   * @public
   */
  reset() {
    this.ability.update([])
  }

  /**
   * Определение правил CASL на основе пермишенов и модулей для роутов
   *
   * @param {Object} args
   * @param {Object[]} args.modules
   * @param {string[]} args.permissions
   * @returns {Array}
   * @public
   */
  defineFor({ modules, permissions }) {
    // Супер-админ
    // TODO: перенести логику в mappers.js, не выдавать все доступные абилити
    //       супер-админу, а перечислить недостающие и запрещающие
    //       как и для всех остальных пермишенов
    if (permissions.includes('admin_panel')) {
      // Супер-админ может все и везде (убрать в будущем)
      this.abilityBuilder.can('manage', 'all')

      // Только для супер-админа:
      this.abilityBuilder.can(userActions.UPDATE_SUPPORT_VOTE_COUNT, 'all')
      this.abilityBuilder.can(
        userActions.UPDATE_SUPPORT_QUESTION_DEPARTMENTS,
        'all',
      )
      this.abilityBuilder.can(userActions.UPDATE_ACTIVE_SCHOOL, 'all')
      this.abilityBuilder.can(userActions.UPDATE_SCHOOL_MODULES, 'all')

      // Ограничивающие правила для супер-админа:
      this.abilityBuilder.cannot(userActions.UPDATE_CLIENT_TEST_FLAG, 'all')
      this.abilityBuilder.cannot(userActions.UPDATE_STUDENT_TEST_FLAG, 'all')
    } else {
      // Главная страница по-умолчанию, доступна всем
      this.abilityBuilder.can(userActions.READ, 'admin-main')
      this.abilityBuilder.can(userActions.READ_MENU_ITEM, 'admin-main')

      permissions.forEach((permission) => {
        permissionToRules(
          {
            activeModules: modules,
            permission,
          },
          {
            can: this.abilityBuilder.can,
          },
        )
      })
    }

    this.ability.update(this.abilityBuilder.rules)

    return this.abilityBuilder.rules
  }
})()
