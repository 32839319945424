const routes = [
  {
    path: '/online-lessons',
    name: 'admin-online-lessons',
    meta: {
      accessControlGroup: 'online-lesson',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-online-lessons" */ '@admin/pages/admin/onlineLesson/OnlineLessons.vue'
      ),
  },
  {
    path: '/online-lesson/create',
    name: 'admin-the-online-lesson-create',
    meta: {
      accessControlGroup: 'online-lesson',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-online-lesson" */ '@admin/pages/admin/onlineLesson/TheOnlineLesson.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/online-lesson/:onlineLessonId(\\d+)',
    name: 'admin-the-online-lesson',
    meta: {
      accessControlGroup: 'online-lesson',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-online-lesson" */ '@admin/pages/admin/onlineLesson/TheOnlineLesson.vue'
      ),
  },

  {
    path: '/online-lesson-rooms',
    name: 'admin-online-lesson-rooms',
    meta: {
      accessControlGroup: 'online-room',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-online-lesson-rooms" */ '@admin/pages/admin/onlineLessonRoom/OnlineLessonRooms.vue'
      ),
  },
  {
    path: '/online-lesson-room/create',
    name: 'admin-the-online-lesson-room-create',
    meta: {
      accessControlGroup: 'online-room',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-online-lesson-room" */ '@admin/pages/admin/onlineLessonRoom/TheOnlineLessonRoom.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/online-lesson-room/:onlineLessonRoomId(\\d+)',
    name: 'admin-the-online-lesson-room',
    meta: {
      accessControlGroup: 'online-room',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-the-online-lesson-room" */ '@admin/pages/admin/onlineLessonRoom/TheOnlineLessonRoom.vue'
      ),
  },
]

export default routes
