import storage from '@frontend/helpers/storage'
import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

/**
 * Назначение нового класса темы
 *
 * @param {Object} variants
 * @param {string} newClass
 */
const _setCurrentTheme = (variants, newClass) => {
  const docClassList = document.documentElement.classList

  if (!docClassList.contains(newClass)) {
    if (docClassList.contains(variants.dark)) {
      docClassList.remove(variants.dark)
      docClassList.add(newClass)
    } else {
      docClassList.remove(variants.light)
      docClassList.add(newClass)
    }
  }
}

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTheme(state, payload = state.variants.default) {
    storage.set('adminSavedTheme', payload)

    state.currentTheme = payload

    _setCurrentTheme(state.variants, payload)
  },

  setIsLoading(state, payload = false) {
    state.isLoading = payload
  },
}
