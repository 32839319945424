import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonConferenceBigBlueButton = new (class extends ApiBase {
  /**
   * Создание новой конференции
   *
   * @param {number} onlineLessonId
   * @returns {Promise<Object>}
   */
  post(onlineLessonId) {
    return this._POST(`/online-lessons/${onlineLessonId}/conference`)
  }

  /**
   * Вход в существующую конференцию
   *
   * @param {number} onlineLessonId
   * @returns {Promise<Object>}
   */
  get(onlineLessonId) {
    return this._GET(`/online-lessons/${onlineLessonId}/conference`)
  }
})()
