const routes = [
  {
    path: 'analytics',
    name: 'admin-analytics',
    component: () =>
      import(
        /* webpackChunkName: "admin-analytics" */ '@admin/pages/admin/analytics/Analytics.vue'
      ),
  },

  {
    path: 'student-enrolled',
    name: 'admin-analytics-student-enrolled',
    component: () =>
      import(
        /* webpackChunkName: "admin-analytics-student-enrolled" */ '@admin/pages/admin/analytics/StudentEnrolled.vue'
      ),
  },

  {
    path: 'analytics/ctp',
    name: 'admin-analytics-ctp',
    component: () =>
      import(
        /* webpackChunkName: "admin-analytics-ctp" */ '@admin/pages/admin/analytics/Ctp.vue'
      ),
  },

  {
    path: 'analytics/library',
    name: 'admin-analytics-library',
    component: () =>
      import(
        /* webpackChunkName: "admin-analytics-library" */ '@admin/pages/admin/analytics/Library.vue'
      ),
  },
]

export default routes
