import ApiBase from 'ApiRest/ApiBase'

export const ApiTimetable = new (class extends ApiBase {
  /**
   * @param {number} timetableId
   * @returns {Promise}
   */
  get(timetableId) {
    return this._GET(`/school/timetable/admin/${timetableId}`)
  }

  /**
   * @param {{}} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/school/timetable/admin/', data)
  }

  /**
   * @param {{}} data
   * @returns {Promise}
   */
  patch(data) {
    return this._PATCH('/school/timetable/admin', data)
  }

  /**
   * @param {number} timetableId
   * @returns {Promise}
   */
  delete(timetableId) {
    return this._DELETE(`/school/timetable/admin/${timetableId}`)
  }
})()
