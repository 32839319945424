import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiOnlineLessonGroup = new (class extends ApiBase {
  /**
   * Групповое обновление
   *
   * @param {number} onlineLessonId
   * @param {{editAll: boolean}|Object} payload
   * @returns {Promise}
   */
  patch(onlineLessonId, payload) {
    return this._PATCH(`/online-lessons/group/${onlineLessonId}`, payload)
  }

  /**
   * Групповое удаление
   *
   * @param {number} onlineLessonId
   * @param {{deleteAll: boolean}} params
   * @returns {Promise}
   */
  delete(onlineLessonId, params) {
    return this._DELETE(
      `/online-lessons/group/${onlineLessonId}?${UrlHelper.param(params)}`,
    )
  }
})()
